/* Jakarta Font */
@font-face {
	font-family: 'Jakarta';
	src: url('./assets/fonts/PlusJakartaSans-Medium.ttf');
}

html {
	height: 100%;
	width: 100%;
}

* {
	font-family: 'Jakarta', sans-serif;
	margin: 0;
	padding: 0;
}

body {
	/* background-color: #FCEBE3 !important; */
	height: 100%;
}

a:link {
	text-decoration: none;
}

#root {
	height: 100%;
}

p {
	margin: 0 !important;
}

.tippy-popper {
	pointer-events: auto !important;
}

/* to prevent blurry content in tooltips */
.tippy-popper,
.tippy-tooltip,
.tippy-tooltip-content {
	-webkit-font-smoothing: antialiased !important;
}

.tippy-tooltip {
	padding: 0rem 0.1rem !important;
}

/* Custom classes */
.custom-navbar {
	position: sticky;
	top: 0;
	z-index: 1020;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
}

/* override navbar fluid styles */
#navbarContainer {
	padding-left: 0px !important;
}

.flex-container {
	display: flex;
	align-items: center;
	justify-content: center;
}
.vertical-flex-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.full-flex-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
